// 原生 百度地图
<template>
  <div>
    <div class="bm-view" id="container">
    </div>
  </div>
</template>

<script>
// 点图标
import markIcon from '../../assets/icon.png'
import wx from 'weixin-js-sdk';
import {BMPGL} from "../../commons/bmpgl"
import indexSystem from "@/commons/indexSystem";

export default {
  data() {
    return {
      markIcon: markIcon
    }
  },
  methods: {
    goto: function (url) {
      this.$router.push(url)
    },
    initMap() {
      var that = this
      // 传入密钥获取地图回调。
      BMPGL('oBvDtR6nzWtVchkY4cLHtnah1VVZQKRK').then((BMapGL) => {
        // 创建地图实例
        let map = new BMapGL.Map("container", {
          minZoom: 18,
          maxZoom: 20
        });
        map.centerAndZoom(new BMapGL.Point(117.369955, 39.514800), 19);
        // map.enableRotateGestures(false)

        map.enableScrollWheelZoom(true)
        // map.enableRotateGestures(true)
        // map.setTilt(45);
        map.setDisplayOptions({
          poiText: false,  // 隐藏poi标注
          poiIcon: true,  // 隐藏poi图标
          building: true  // 隐藏楼块
        });
        // map.addEventListener('click', function (e) {
        //   alert('点击位置经纬度：' + e.latlng.lng + ',' + e.latlng.lat);
        // });
        var pStart = new BMapGL.Point(117.363887, 39.516682);
        var pEnd = new BMapGL.Point(117.376328, 39.510959);
        var bounds = new BMapGL.Bounds(new BMapGL.Point(pStart.lng, pEnd.lat), new BMapGL.Point(pEnd.lng, pStart.lat));
        var imgOverlay = new BMapGL.GroundOverlay(bounds, {
          type: 'image',
          url: indexSystem.MapUrl + "baidu/map.png",
          opacity: 1
        });
        map.addOverlay(imgOverlay);

        var markerArr = [
          {
            longitude: '117.37123508535164',
            latitude: '39.51627409152404',
            customData: {name: '村委会', id: 'cwh'},
            icon: indexSystem.MapUrl + 'baidu/cwh.png',
            iconWidth: 305,
            iconHight: 104
          },
          {
            longitude: '117.36886805033285',
            latitude: '39.5159465101333',
            customData: {name: '国际纤维艺术中心', id: 'gjxwyszx'},
            icon: indexSystem.MapUrl + 'baidu/gjxwyszx.png',
            iconWidth: 538,
            iconHight: 104
          },
          {
            longitude: '117.3670624562578',
            latitude: '39.51575882906718',
            customData: {name: '公园', id: 'gy'},
            icon: indexSystem.MapUrl + 'baidu/gy.png',
            iconWidth: 246,
            iconHight: 104
          },
          {
            longitude: '117.3720705094759',
            latitude: '39.5149096920048',
            customData: {name: '老槐树', id: 'lks'},
            icon: indexSystem.MapUrl + 'baidu/lks.png',
            iconWidth: 305,
            iconHight: 104
          },
          {
            longitude: '117.36918245726135',
            latitude: '39.51543503153741',
            customData: {name: '琉璃珠', id: 'llz'},
            icon: indexSystem.MapUrl + 'baidu/llz.png',
            iconWidth: 293,
            iconHight: 104
          },
          {
            longitude: '117.37198966198002',
            latitude: '39.51398772883885',
            customData: {name: '水岸森林', id: 'sasl'},
            icon: indexSystem.MapUrl + 'baidu/sasl.png',
            iconWidth: 356,
            iconHight: 104
          },
          {
            longitude: '117.37309906928485',
            latitude: '39.51526455755848',
            customData: {name: '石板桥', id: 'sdq'},
            icon: indexSystem.MapUrl + 'baidu/sdq.png',
            iconWidth: 305,
            iconHight: 104
          },
          {
            longitude: '117.37006728818868',
            latitude: '39.514927087417675',
            customData: {name: '手鞠球', id: 'sjq'},
            icon: indexSystem.MapUrl + 'baidu/sjq.png',
            iconWidth: 302,
            iconHight: 104
          },
          {
            longitude: '117.37035474595187',
            latitude: '39.514540908218585',
            customData: {name: '水木三乡书院', id: 'smsxsy'},
            icon: indexSystem.MapUrl + 'baidu/smsxsy.png',
            iconWidth: 456,
            iconHight: 104
          },
          {
            longitude: '117.3690252537971',
            latitude: '39.514575699226114',
            customData: {name: '鱼塘', id: 'yt'},
            icon: indexSystem.MapUrl + 'baidu/yt.png',
            iconWidth: 255,
            iconHight: 104
          }
        ]
        for (let i = 0; i < markerArr.length; i++) {//遍历点位
          var markerArrI = markerArr[i];
          var point = new BMapGL.Point(markerArrI.longitude, markerArrI.latitude);
          var icon = new BMapGL.Icon(markerArrI.icon, new BMapGL.Size(markerArrI.iconWidth / 3, markerArrI.iconHight / 3));
          var marker = new BMapGL.Marker(point, {icon: icon});//把icon和坐标添加到Marker中

          // 额外参数得加到这个里面 customData
          marker.customData = markerArrI.customData
          // console.log(marker[i]);
          marker.addEventListener("click", (e) => {
            console.log(e.target.customData);
            // 跳小程序
            that.gotoAttractionsDetails(e.target.customData.id)
            console.log(e.point.lng, e.point.lat) // 点击位置
            // console.log(e.targe.customData)  // 点击的聚合中的marker点聚合

          });
          console.log(marker)
          map.addOverlay(marker);
        }


      }).catch((err) => {
        console.log(err)
      })
    },
    //跳转到小程序详情
    gotoAttractionsDetails: function (id) {
      this.$request.requestNoToken({
        url: 'Api/cmsSystem/WxCmsModule/CmsScenicSpot/GetOneCmsScenicSpotByCmsScenicMapType',
        data: {
          cmsScenicMapType: id
        }
      }).then(res => {
        if (res.code == 0) {
          wx.miniProgram.navigateTo({
            url: '/hostHomeSubpackage/introduction/introduction?id=' + res.data.cmsScenicSpotID
          });
        }
      })

    }
  },
  mounted() {
    this.initMap()
    document.title = '三维地图'

  },
  components: {}
}
</script>

<style>
.bm-view {
  width: 100%;
  height: 100%;
  position: absolute;
}

.bm-view /deep/ img {
  border: none;
}

.BMap_cpyCtrl {
  display: none;
}

.map_div /deep/ .BMap_bubble_content {
  padding-top: 8px;
}

.anchorBL {
  display: none;
}

.bm-button {
  width: 100px;
  margin: auto;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
}

.bm-button-div {
  position: fixed;
  z-index: 9999;
  right: 0px;
}
</style>
