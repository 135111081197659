import { render, staticRenderFns } from "./baidu.vue?vue&type=template&id=ada6f33c"
import script from "./baidu.vue?vue&type=script&lang=js"
export * from "./baidu.vue?vue&type=script&lang=js"
import style0 from "./baidu.vue?vue&type=style&index=0&id=ada6f33c&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports